/**
 * Browser-History manipulation (make the browser back button close the modal)
 * Event-Listener to disable zoom
 */

var molModalExtensions = (function ( $ ) {

 	var molModal_pushStateIsSupported = true;
  //var molModal_openerUrl = '';


  /**
   * Start browser-history manipulation (make the browser back button close the modal)
   * - add new entry to the History
   * - add popstate listener to close the modal
   */
 	$.fn.molModalStartBrowserHistoryManipulation = function(){
 		//molModal_openerUrl = window.location.href;
    var molCurrentHash = window.location.hash;
    var _this_element_id = $(this).attr('id');


      try {

        if(molCurrentHash == '#'+_this_element_id) {
          // we already have a history entry for this modal
        }
        else {
					var stateObj = { mol_type: "molModalOverlay" };
          history.pushState(stateObj, "", "#"+_this_element_id);
        }
         // close modal on popsate (most likely the browser back button)
         $(window).one('popstate.molCloseModalOnPopstate', function (popstateEvent) {
          $('#'+_this_element_id).modal('hide');
         });

       } catch(catchedError) {
         molModal_pushStateIsSupported = false;
       }

  	};

  /**
   * End browser-history manipulation
   * - remove popstate listener
   * - go back in browser history if neccessary
   */
  $.fn.molModalEndBrowserHistoryManipulation = function(){

     if(molModal_pushStateIsSupported)
     {
       // remove popstate listener
       $(window).off('popstate.molCloseModalOnPopstate');

       var _this_element_id = $(this).attr('id');

       if(window.location.hash == '#'+_this_element_id) {

         // we are still on the manipulated url with hash
         // go back in browser history
         try {
             window.history.go(-1);
         }
         catch(catchedError) {
         }
       }
     }
 	};

  /**
   * Start disabling zoom
   */
  $.fn.molModalStartDisablingZoom = function(){
    // disable pinch to zoom
    window.addEventListener('touchstart', $.fn.molDisablePinchToZoom, { passive: false });
    window.addEventListener('touchmove', $.fn.molDisablePinchToZoom, { passive: false });
  };

  /**
   * End disabling zoom
   */
  $.fn.molModalEndDisablingZoom = function(){
    window.removeEventListener('touchstart', $.fn.molDisablePinchToZoom);
    window.removeEventListener('touchmove', $.fn.molDisablePinchToZoom);
  };
 }( jQuery ));

export default molModalExtensions
