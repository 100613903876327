var molOffcanvas = (function ( $ ) {

    var _clickedButton = "";
    var _target = "";
    var _dialog = "";
    var _backdrop = "";
    var _dismiss = "";
    var _isShown = false;
    var _pushStateIsSupported = true;
    var _openerUrl = '';

    // plugin
    $.fn.molOffcanvas = function (){

      this.on("click", function(eventObject){

        eventObject.preventDefault();

        _clickedButton = this;
        _target = $('#'+$(this).data('target'));

        if(!_target.length)
        {
          return;
        }

        // colorpicker selected color offcanvas...
        if(_target.attr('id') == 'molSelectedColorOffcanvas')
        {
          if(_target.css('opacity') == 1)
          {
            // do not show offCanvas.
            // the element is already shown at xl
            return;
          }
        }

        _dialog =   _target.children('.mol-offcanvas-dialog');
        _backdrop = _target.children('.mol-offcanvas-backdrop');
        _dismiss =  _target.find('[data-dismiss="molOffcanvas"]');

        _backdrop.on("click", function(){
          hide();
        });

        _dismiss.on("click", function(){
          hide();
        });

        show();
      });
    };

    // show function
    function show (){

      if(_isShown)
      {
        return;
      }

      _isShown = true;

      // get heights before body manipulation
      var docHeight = $(document).height();
      var winHeight = $(window).height();

      _target.addClass('mol-offcanvas-open');
      _dialog.addClass('mol-offcanvas-open');
      _backdrop.addClass('mol-offcanvas-backdrop-open');
      $('body').addClass('mol-offcanvas-backdrop-open');

      // add padding to the body to simulate scrollbar
      if(docHeight > winHeight) {
        var _scrollbarWidth = getScrollbarWidth();
        $('body').css('padding-right', _scrollbarWidth);
      }

      // change aria label
      $(_clickedButton).attr('aria-expanded', 'true');

      // manipulate borwser history
      _openerUrl = window.location.href;

      try {
         // add entry to browser history
         var stateObj = { mol_type: "overlayDummy" };
         history.pushState(stateObj, "", "#"+_target.attr('id'));

         // close modal on popsate (most likely the browser back button)
         $(window).one('popstate.molCloseModalOnPopstate', function (popstateEvent) {
          _dismiss.trigger("click");
         });

       } catch(catchedError) {
         _pushStateIsSupported = false;
       }
    };

    // hide function
    function hide (){

      if(!_isShown)
      {
        return;
      }

      _isShown = false;

      _dialog.removeClass('mol-offcanvas-open');
      _backdrop.removeClass('mol-offcanvas-backdrop-open');
      $('body').removeClass('mol-offcanvas-backdrop-open');
      $('body').css('padding-right', '');

      setTimeout(function(){
        _target.removeClass('mol-offcanvas-open');
        // scroll back to top
        _dialog.scrollTop( 0 );
      }, 400);

      // change aria label
      $(_clickedButton).attr('aria-expanded', 'false');

      // go back in browser history
      if(_pushStateIsSupported)
      {
        $(window).off('popstate.molCloseModalOnPopstate');
        if(window.location.href != _openerUrl) {
          // we are still on the manipulated url with hash
          try {
            window.history.go(-1);
          }
            catch(catchedError) {
          }
        }
      }
    };

    // util functions
    function getScrollbarWidth () {
      // thx d.walsh
      var scrollbarWidth = 0;

      var scrollDiv = document.createElement('div');
      scrollDiv.className = 'mol-offcanvas-scrollbar-measure';
      document.body.appendChild(scrollDiv);
      scrollbarWidth = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;
      document.body.removeChild(scrollDiv);

      return scrollbarWidth;
    }; // Static

}( jQuery ));

export default molOffcanvas
