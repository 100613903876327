var molSmoothScroll = (function ( $ ) {

	// element to scroll to
	var _target = "";

	// plugin
    $.fn.molSmoothScroll = function (){

      this.on("click", function(eventObject){

		_target = $(this.hash);
		_target = _target.length ? _target : $('[name=' + this.hash.slice(1) + ']');

		// Does a scroll target exist?
		if (_target.length) {

			eventObject.preventDefault();

			$('html, body').animate({
			  scrollTop: _target.offset().top
			}, 800, function() {
			  // Callback after animation
			  // Must change focus!
			  var $target = $(_target);
			  $target.focus();
			  if ($target.is(":focus")) { // Checking if the target was focused
				return false;
			  } else {
				$target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
				$target.focus(); // Set focus again
			  };
			});
		}
	  });
	};

}( jQuery ));

// prevent pinch to zoom in all Modals
var molDisablePinchToZoom = (function ( $ ) {
	$.fn.molDisablePinchToZoom = function(molModalTouchEvent){

		if (molModalTouchEvent.touches.length == 2) {
			//alert('test');
			molModalTouchEvent.preventDefault();
			molModalTouchEvent.stopImmediatePropagation();
		};
	};
}( jQuery ));

export {molDisablePinchToZoom, molSmoothScroll};
