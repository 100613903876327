/**
 * mol viewport modals with iFrames
 */

var molModalViewportModalFrame = (function ( $ ) {

  /**
   * Start manipulation
   * must be called in show.bs.modal event function
   * - create the iframe with targeted src
   * - calculate sizes of the iFrame and the content container in the framed document
   * - add Event listener on resize for new size calculation
   *
   * param showBsModalEvent [boostrap event object from the show.bs.modal event]
   */
  $.fn.molModalStartViewportModalFrame = function(showBsModalEvent) {

    // create the frame with the targeted src
    // by creating a complete new element, we prevent new entry in the
    // browser history
    var frameUrl = $(showBsModalEvent.relatedTarget).attr('href');
    var frameElement = '<iframe class="mol-viewport-modal-frame d-block" id="molViewportModalFrame" src="'+frameUrl+'"></iframe>';
    $(this).find('#molViewportModalFrameDummy').replaceWith(frameElement);

    var modalId = $(this).attr('id');
    var modalMarginY = 15;

    // calculate height of the the frame and the content container in the frame
    var windowHeight = window.innerHeight ? window.innerHeight : $(window).height();
    var windowWidth = window.innerWidth ? window.innerWidth : $(window).width();

    if(modalId == 'molCcePickerModal')
    {
      modalMarginY = 5;

      if(windowWidth > 992)
      {
        modalMarginY = 15;
      }
    }

    // windowHeight - modal-border top & bottom (2 * 1px) - modal-margin top & bottom (2 * 15px)
    var height = windowHeight - (2 + modalMarginY + modalMarginY);

    $('#molViewportModalFrame').css('height', height);

    $('#molViewportModalFrame').one('load', function() {
      var iFrameDOM = $('#molViewportModalFrame').contents();
      iFrameDOM.find(".mol-viewport-modal-frame-content-container").css('height', height);
    });

    var resizeTimer;

    // resize needed in safari @ iOS when body is fixed,
    // because the fixed body toogles the browser-bars
    if($('body').hasClass('mol-modal-open-body-fixed'))
    {
      $('#molViewportModalFrame').on('load', function() {
        resizeTimer = setTimeout(function() {

          console.log('viewportModalResizer');
          // calculate height of the the frame and the content container in the frame
          var windowHeight = window.innerHeight ? window.innerHeight : $(window).height();

          // windowHeight - modal-border top & bottom (2 * 1px) - modal-margin top & bottom (2 * 15px)
          var height = windowHeight - (2 + modalMarginY + modalMarginY);

          $('#molViewportModalFrame').css('height', height);

          var iFrameDOM = $('#molViewportModalFrame').contents();
          iFrameDOM.find(".mol-viewport-modal-frame-content-container").css('height', height);
        }, 500);
      });
    }

    $(window).on('resize.molViewportModal', function (e) {

      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function() {

        console.log('viewportModalResizer');
        
        // calculate height of the the frame and the content container in the frame
        var windowHeight = window.innerHeight ? window.innerHeight : $(window).height();

        // windowHeight - modal-border top & bottom (2 * 1px) - modal-margin top & bottom (2 * 15px)
        var height = windowHeight - (2 + modalMarginY + modalMarginY);

        $('#molViewportModalFrame').css('height', height);

        var iFrameDOM = $('#molViewportModalFrame').contents();
        iFrameDOM.find(".mol-viewport-modal-frame-content-container").css('height', height);
      }, 200);
    });
  };

  /**
   * End manipulation
   * must be called in the hidden.bs.modal event function
   * - remove iFrame from DOM / replace it with the dummy
   * - remove resize listener
   */
  $.fn.molModalEndViewportModalFrame = function() {
    // remove iFrame from DOM / replace it with the dummy
    $('#molViewportModalFrame').replaceWith('<div id="molViewportModalFrameDummy"></div>');

    //remove resize listener
    $(window).off('resize.molViewportModal');
  };

}( jQuery ));

export default molModalViewportModalFrame
