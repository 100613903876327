var molForm = (function ( $ ) {

  $.fn.molFormValidation = function() {
    "use strict";

    //var forms = this;

    // Loop over the Forms and prevent submission if there are invalid fields
    var validation = Array.prototype.filter.call(this, function(form) {
      form.addEventListener('submit', function(event) {
        if (form.checkValidity() === false) {

          event.preventDefault();
          event.stopPropagation();

          // scroll to first invalid vield
          var firstInvalidElement = $(form).find('.form-check-input:invalid, .custom-select:invalid, .form-control:invalid').first();
          var firstInvalidElementLabel = $(form).find("label[for='"+firstInvalidElement.attr('id')+"']");
          var newScrollTop = firstInvalidElement.offset().top;

          if(firstInvalidElementLabel.length) {
            newScrollTop = firstInvalidElementLabel.offset().top;
          }

          newScrollTop = newScrollTop -10;

          $('html,body').animate({scrollTop: newScrollTop});
        }
        form.classList.add('was-validated');
      }, false);
    });
  };

}( jQuery ));

export default molForm
