var molProcessHistoryState = (function ( $ ) {

  // DEBUG LOGS
  /*console.log(history.state);
  $(window).on('popstate', function(popstateEvent) {
    alert('popstate ');
		console.log(popstateEvent.originalEvent);
  });*/

  if(history.state)
  {   
		var molStateObj = history.state;

    if(molStateObj.mol_type)
    {      
			if(molStateObj.mol_type == "overlayDummy")
			{
				// go back in browser history
        try {          
					window.onload = function(event) {
						window.history.go(-1);
					}
        }
        catch(catchedError) {
        }
				
				// force iOS/Safari to load the page
				/*$('body').css('opacity', '0');
				var stateObj = { mol_type: "goBack" };
				history.replaceState(stateObj, "", location.href);
				window.location.reload(true);*/
			}
			/*else if(molStateObj.mol_type == "goBack")
      {
        // go back in browser history
        try {
          //alert('goback by history.state.mol_type');
					window.onload = function(event) {
						window.history.go(-1);
					}
        }
        catch(catchedError) {
        }
      }*/
    }
  }

}( jQuery ));

export default molProcessHistoryState
